import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  createTransform,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { validateLang } from "../data/helpers";
import { userReducer } from "./user/slice";
import { cartReducer } from "./cart/slice";

const SetTransform = createTransform(
  (inboundState, key) => {
    return inboundState;
  },
  (outboundState, key) => {
    key === "lang" && (outboundState = validateLang(outboundState));
    return outboundState;
  },
  { whitelist: ["contacts", "lang"] }
);

const userPersistConfig = {
  key: "user",
  storage,
  transforms: [SetTransform],
  whitelist: ["contacts", "lang"],
};

const cartPersistConfig = {
  key: "cart",
  storage,
  whitelist: ["cart"],
};

export const store = configureStore({
  reducer: {
    user: persistReducer(userPersistConfig, userReducer),
    cart: persistReducer(cartPersistConfig, cartReducer),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
