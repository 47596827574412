import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react/dist/iconify.js";
import { getCart, getLang } from "../redux/selectors";
import { decreaseQty, increaseQty, removeFromCart } from "../redux/cart/slice";
import { cloudinary_base_url } from "../data/constants";
import { cutName } from "../data/helpers";

export const CartList = () => {
  const cartList = useSelector(getCart);
  const lang = useSelector(getLang);
  const dispatch = useDispatch();

  const cloudPreset = "c_scale,w_256/q_auto/f_webp/Multicook/products/";

  return (
    <ul className="flex flex-col items-start max-h-[70vh] md:max-h-[60vh] overflow-y-auto">
      {cartList.map((el) => (
        <li
          key={el.productId}
          className="flex items-center h-[108px] md:h-[140px] py-[10px] md:py-[20px] border-b-2 border-gray-300 w-full md:text-xl"
        >
          <img
            src={cloudinary_base_url + cloudPreset + el.productImgCDN}
            alt={el.productName[lang]}
            loading="lazy"
            className="h-full w-[86px] md:w-[100px] object-cover mr-5 rounded-md"
          />
          <div className="h-full flex flex-col justify-between mr-5 md:mr-10 ">
            <div className="font-semibold text-sm md:text-base">
              <p>{cutName(el.productName[lang], "VEGA")}</p>
              <div className="flex ">
                {el.option ? <p className="mr-4">{el.option}</p> : null}
                {el.weight ? <p>{el.weight} g</p> : null}
              </div>
            </div>
            <div className="flex items-center">
              <span className="mr-5 text-sm md:text-base">quantity: </span>
              <Icon
                icon="lucide:minus"
                className={`font-bold mx-2 ${
                  el.qty !== 1
                    ? "bg-mainGreen hover:text-mainRed cursor-pointer"
                    : "bg-bluredRed "
                }  rounded-full text-2xl`}
                onClick={
                  el.qty !== 1
                    ? () => dispatch(decreaseQty(el.cartId))
                    : () => {}
                }
              />
              <span className="font-bold font-lato px-2">{el.qty}</span>
              <Icon
                icon="lucide:plus"
                className="font-bold mx-2 bg-mainGreen hover:text-mainRed cursor-pointer rounded-full text-2xl"
                onClick={() => dispatch(increaseQty(el.cartId))}
              />
            </div>
          </div>
          <div className="ml-auto mr-2 flex flex-col justify-between items-end h-full">
            ${(el.price * el.qty).toFixed(2)}
            <Icon
              icon="gravity-ui:trash-bin"
              className="text-mainRed cursor-pointer hover:bg-bluredBlack p-1 text-3xl rounded-full"
              onClick={() => dispatch(removeFromCart(el.cartId))}
            />
          </div>
        </li>
      ))}
    </ul>
  );
};
