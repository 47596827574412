import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cart: [],
  isCartUpdated: false,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
      const idx = state.cart.findIndex(
        (item) => item.productId === action.payload.productId
      );
      if (idx !== -1 && state.cart[idx].option === action.payload.option) {
        state.cart[idx].qty = state.cart[idx].qty + 1;
        return;
      }
      state.cart.push(action.payload);
    },
    removeFromCart(state, action) {
      const idx = state.cart.findIndex(
        (item) => item.cartId === action.payload
      );
      state.cart.splice(idx, 1);
    },
    increaseQty(state, action) {
      const idx = state.cart.findIndex(
        (item) => item.cartId === action.payload
      );
      state.cart[idx].qty = state.cart[idx].qty + 1;
    },
    decreaseQty(state, action) {
      const idx = state.cart.findIndex(
        (item) => item.cartId === action.payload
      );
      state.cart[idx].qty = state.cart[idx].qty - 1;
    },
    updateCart(state) {
      state.isCartUpdated = !state.isCartUpdated;
    },
    clearCart(state) {
      state.cart = [];
    },
  },
});

export const {
  addToCart,
  removeFromCart,
  increaseQty,
  decreaseQty,
  updateCart,
  clearCart,
} = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
