import { useState } from "react";
import { useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { Icon } from "@iconify/react/dist/iconify.js";
import { getLang } from "../redux/selectors";
import { Contacts } from "./Contacts";
import { Social } from "./Social";
import { MenuLang } from "./MenuLang";
import { MenuCategories } from "./MenuCategories";
import { CartBtn } from "./CartBtn";

export const Header = () => {
  const lang = useSelector(getLang);

  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [langOpen, setLangOpen] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuOpen(false);
    setLangOpen(false);
  };

  return (
    <header className="w-full sticky top-0 z-[1001] bg-mainRed px-4 md:px-8 flex">
      <div
        className={`flex order-2 xl:order-1 items-center xl:justify-between justify-center xl:w-1/3 w-1/5 text-mainWhite`}
      >
        <HashLink to={"#home"} smooth>
          <img
            loading="lazy"
            src="https://res.cloudinary.com/dvloxectq/image/upload/v1739930864/Multicook/oie_eLxgXKHUx4nU_ogymnd.png"
            alt="Multicook"
            className="h-[70px] lg:h-[90px] pb-2 "
          />
        </HashLink>
        <Contacts position={"header"} />
      </div>
      <div className="xl:w-1/3 w-2/5 order-1 xl:order-2 ">
        <h1 className="uppercase font-extrabold text-mainWhite h-full flex flex-col justify-center text-base md:text-xl lg:text-4xl py-2">
          <p>handmade</p>
          <p>frozen food</p>
        </h1>
      </div>
      <div className="xl:w-1/3 w-2/5 flex order-3 items-center justify-center gap-4 base:justify-between base:gap-0">
        <button
          className="btn hidden lg:block text-mainWhite border-mainWhite uppercase w-28 hover:text-mainGreen hover:border-mainGreen"
          onClick={(e) => {
            handleClick(e);
            setMenuOpen(true);
          }}
        >
          {lang === "en" ? "menu" : "меню"}
        </button>
        <Icon
          icon="material-symbols-light:menu-rounded"
          className="block lg:hidden text-mainWhite text-6xl md:text-7xl ml-4 cursor-pointer hover:text-mainGreen"
          onClick={(e) => {
            handleClick(e);
            setMenuOpen(true);
          }}
        />
        <Social position={"header"} />
        <div className="font-light text-mainWhite text-4xl flex items-center justify-between gap-3">
          <CartBtn />
          <Icon
            icon="fluent:globe-32-regular"
            className="md:text-3xl hover:text-mainGreen cursor-pointer hidden md:block"
            onClick={(e) => {
              handleClick(e);
              setLangOpen(true);
            }}
          />
        </div>
      </div>
      <MenuLang anchorEl={anchorEl} open={langOpen} handleClose={handleClose} />
      <MenuCategories
        anchorEl={anchorEl}
        open={menuOpen}
        handleClose={handleClose}
      />
    </header>
  );
};
