import { useState } from "react";
import { useSelector } from "react-redux";
import { Icon } from "@iconify/react/dist/iconify.js";
import { CartDialog } from "./CartDialogs";
import { getCart, getIsCartUpdated } from "../redux/selectors";

export const CartBtn = () => {
  const cart = useSelector(getCart);
  const isCartUpdated = useSelector(getIsCartUpdated);
  const qty = cart.reduce((acc, curr) => acc + curr.qty, 0);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <span onClick={handleClickOpen} className="cursor-pointer">
        {!qty ? (
          <Icon
            icon="ion:cart-outline"
            className="hover:text-mainGreen  text-[2.5rem]"
          />
        ) : (
          <span className="relative">
            <Icon
              icon="ion:cart"
              className="hover:text-mainGreen  text-[2.5rem]"
            />
            <span
              className={`${
                isCartUpdated
                  ? "scale-[2] -left-[120px] top-2 md:top-0 md:-left-[200px]"
                  : "left-0"
              } absolute font-sans -top-2 text-base font-bold text-mainBlack rounded-full bg-mainGreen px-1 min-w-6 min-h-6 transition-all duration-300 ease-in-out transform cursor-pointer`}
            >
              {qty > 99 ? (
                <Icon
                  icon="icomoon-free:infinite"
                  className="translate-y-1 text-lg"
                />
              ) : (
                qty
              )}
            </span>
          </span>
        )}
      </span>
      <CartDialog open={open} handleClose={handleClose} />
    </>
  );
};
