import { useRef } from "react";
import Carousel from "react-multi-carousel";
import useFirstViewportEntry from "../hooks/useFirstViewportEntry";
import { viewportData } from "../data/constants";
import { CustomLeftArrow, CustomRightArrow } from "./CustomButtons";
import { ProductCard } from "./ProductCard";
import "react-multi-carousel/lib/styles.css";
import { useSelector } from "react-redux";
import { getLang } from "../redux/selectors";

export const MenuSection = ({ data }) => {
  const ref = useRef(null);
  const isVisible = useFirstViewportEntry(ref);
  const lang = useSelector(getLang);

  return (
    <div
      id={`menu_${data.categoryId}`}
      className={`menu-section ${
        isVisible > 0 && "visible"
      } mt-[-100px] pt-[100px] md:mt-[-100px] md:pt-[100px]`}
      ref={ref}
    >
      <h3 className="section-header">{data.categoryName[lang]}</h3>
      <Carousel
        responsive={viewportData}
        customRightArrow={<CustomRightArrow />}
        customLeftArrow={<CustomLeftArrow />}
      >
        {data.products.map(
          (el) => el.show && <ProductCard key={el.productId} product={el} />
        )}
      </Carousel>
    </div>
  );
};
