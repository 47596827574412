import { useDispatch, useSelector } from "react-redux";
import { HashLink } from "react-router-hash-link";
import { Menu } from "@mui/material";
import { Icon } from "@iconify/react/dist/iconify.js";
import { products } from "../data/products";
import { Social } from "./Social";
import { Contacts } from "./Contacts";
import { getLang } from "../redux/selectors";
import { switchLang } from "../redux/user/slice";

export const MenuCategories = ({ anchorEl, open, handleClose }) => {
  const lang = useSelector(getLang);
  const dispatch = useDispatch();

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      {products.map((el) => (
        <li
          className="py-1 px-6 text-xl"
          key={el.categoryId}
          onClick={handleClose}
        >
          <HashLink to={`#menu_${el.categoryId}`} smooth>
            <span className="font-lato w-full">{el.categoryName[lang]}</span>
          </HashLink>
        </li>
      ))}
      <li className="flex md:hidden items-center justify-between py-1 px-6 bg-bluredBlack text-2xl">
        <Social position={"menu"} />
        <div className="flex gap-5 text-3xl">
          <Icon
            icon={"twemoji:flag-canada"}
            className={
              lang !== "en"
                ? "grayscale-[100%] brightness-75"
                : "brightness-100"
            }
            onClick={() => {
              dispatch(switchLang("en"));
            }}
          />
          <Icon
            icon={"twemoji:flag-ukraine"}
            className={
              lang !== "ua"
                ? "grayscale-[100%] brightness-75"
                : "brightness-100"
            }
            onClick={() => {
              dispatch(switchLang("ua"));
            }}
          />
        </div>
      </li>
      <li className="py-0">
        <Contacts position={"menu"} />
      </li>
    </Menu>
  );
};
