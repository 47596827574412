import { useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { cutName } from "../data/helpers";
import { AddDialog } from "./CartDialogs";
import { cloudinary_base_url } from "../data/constants";
import { useSelector } from "react-redux";
import { getLang } from "../redux/selectors";

const cloudPreset = "c_scale,w_512/q_auto/f_webp/Multicook/products/";

export const ProductCard = ({ product }) => {
  const [flipped, setFlipped] = useState({});
  const [open, setOpen] = useState(false);
  const lang = useSelector(getLang);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFlip = (id) => {
    setFlipped((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div key={product.productId} className="product">
      <div className="image-wraper">
        <div
          className={`flip-inner ${
            flipped[product.productId] ? "flipped" : ""
          }`}
        >
          <img
            className="menu-image front-image"
            loading="lazy"
            src={
              product.productImgCDN
                ? cloudinary_base_url + cloudPreset + product.productImgCDN
                : "../images/mock.jpg"
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = "../images/mock.jpg";
            }}
            alt={product.productName.en}
          />

          <div className="back-image pt-4">
            <div className="ingredients-wraper">
              <p className="product-ingredients">{product.ingredients[lang]}</p>
            </div>
            {product.variants.length ? (
              <div className="font-lato mt-1 text-bluredLightGray">
                <p className="font-bold">
                  {lang === "en" ? "Options:" : "Варіанти:"}
                </p>
                {product.variants.map((el, idx) => (
                  <p key={idx}>
                    <span> {el.qty}</span>
                    {el.weight ? <span> / {el.weight}g</span> : null}
                  </p>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <h6 className="product-name mt-4">
        {cutName(product.productName[lang], "VEGA")}
      </h6>
      <div className="flex flex-col justify-end items-center mr-[10px] flex-grow">
        <div className="flex w-full justify-between items-center">
          <button onClick={() => handleFlip(product.productId)}>
            <Icon
              icon="material-symbols-light:list-alt-outline"
              className="list-icon text-6xl text-mainWhite -my-2 hover:text-mainGreen"
            />
          </button>
          <span className="product-price text-lg text-slate-400 font-bold">
            {product.variants.length ? (
              "from $" + product.variants[0].price
            ) : (
              <span className="mr-1">
                {lang === "en" ? "Shop in-store" : "Купуй в магазині"}
              </span>
            )}
          </span>
          {product.variants.length ? (
            <button
              className="btn hover:text-mainGreen hover:border-mainGreen"
              onClick={handleClickOpen}
            >
              {lang === "en" ? "Add to cart" : "В кошик"}
            </button>
          ) : null}
        </div>
      </div>
      {product.vega && <Icon icon="mdi:leaf" className="vegan-icon" />}
      {open && (
        <AddDialog open={open} handleClose={handleClose} product={product} />
      )}
    </div>
  );
};
