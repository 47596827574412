import { Icon } from "@iconify/react/dist/iconify.js";
import { contacts } from "../data/constants";

export const Contacts = ({ position }) => {
  const contactsList = contacts.slice(0, 2);

  return (
    <ul
      className={`${
        position === "menu"
          ? "md:hidden flex flex-col items-st text-sm whitespace-nowrap px-3"
          : ""
      } ${
        position === "footer"
          ? "bg-transparent flex flex-col pl-6 md:pl-10 items-start"
          : "bg-bluredBlack"
      } ${
        position === "top"
          ? "hidden sm:flex justify-center gap-20 xl:hidden w-full mx-auto"
          : position === "menu"
          ? "flex"
          : "hidden xl:block"
      }  p-1 xl:bg-transparent `}
    >
      {contactsList.map((el) => (
        <li
          key={el.id}
          className={position === "footer" ? "py-1 md:py-2 md:mr-10" : ""}
        >
          <a
            href={el.link}
            target={"_blank"}
            rel={"noopener noreferrer"}
            className={`flex items-center lg:text-xl xl:text-base font-semibold justify-center md:justify-start ${
              position === "footer"
                ? "hover:text-bluredBlack md:text-lg"
                : "hover:text-mainGreen"
            }  pt-1 md:py-1`}
          >
            {position !== "menu" && (
              <Icon
                icon={el.icon}
                className={`text-2xl mr-2 text-black ${
                  position === "menu" && "text-xl"
                }`}
              />
            )}
            <p>{el.description}</p>
          </a>
        </li>
      ))}
    </ul>
  );
};
