import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Delivery } from "./components/Delivery";
import { Divider } from "./components/Divider";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { MenuSection } from "./components/MenuSection";
// import { Notice } from "./Notice";
import { SiteCredits } from "./components/SiteCredits";
import { products } from "./data/products";
import { Contacts } from "./components/Contacts";
import "./App.css";
// import { Announce } from "./components/Announce";
// import { GrandOpening } from "./components/GrandOpening";

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <div className="App relative" id="home">
          <Header />
          <Contacts position={"top"} />
          {/* <GrandOpening /> */}
          {/* <Announce /> */}
          {/* <Devider /> */}
          <Delivery />
          <Divider />
          {products.map((el) => (
            <MenuSection data={el} key={el.categoryId} />
          ))}
          <Divider />
          <Footer />
          <SiteCredits />
        </div>
      </BrowserRouter>
    </>
  );
}

export default App;
