import { Icon } from "@iconify/react/dist/iconify.js";

export const CustomRightArrow = ({ onClick }) => {
  return (
    <button
      className="w-10 md:w-12 h-10 md:h-12 absolute right-[calc(3%_+_1px)] shadow-xl bg-mainRed rounded-full flex justify-center items-center text-3xl text-mainWhite border border-bluredBlack"
      onClick={() => onClick()}
    >
      <Icon icon={"octicon:chevron-right-12"} />
    </button>
  );
};

export const CustomLeftArrow = ({ onClick }) => {
  return (
    <button
      className="w-10 md:w-12 h-10 md:h-12 absolute left-[calc(3%_+_1px)] shadow-xl bg-mainRed rounded-full flex justify-center items-center text-3xl text-mainWhite border border-bluredBlack"
      onClick={() => onClick()}
    >
      <Icon icon={"octicon:chevron-left-12"} />
    </button>
  );
};
