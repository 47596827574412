import { Icon } from "@iconify/react/dist/iconify.js";

export const CartEmpty = ({ handleClose }) => {
  return (
    <div className="flex flex-col items-center">
      <Icon icon="mynaui:cart-minus" className="text-9xl" />
      <h3 className="font-bold text-xl my-3">Your cart is empty</h3>
      <p className="my-3">Add tasty products and be happy!</p>
      <button
        onClick={handleClose}
        className="btn bg-mainRed border-mainBlack text-mainWhite hover:text-mainGreen"
      >
        Go Shoping
      </button>
    </div>
  );
};
