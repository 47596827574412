import emailjs from "@emailjs/browser";
import { render } from "@react-email/render";
import env from "react-dotenv";
import { toast } from "react-toastify";
import {
  EmailToAdminTemplate,
  EmailToCustomerTemplate,
} from "../components/EmailTemplates";

const {
  MAILJS_SERVICE_ID,
  MAILJS_CUSTOMER_TEMPLATE_ID,
  MAILJS_ADMIN_TEMPLATE_ID,
  MAILJS_USER_ID,
} = env;

export const cutName = (name, cut) => {
  const arrName = name.split("");
  const idx = name.indexOf(cut);
  if (idx >= 0) arrName.splice(idx, cut.length);
  return arrName.join("");
};

export const validateLang = (lang) => {
  const allowedLangs = ["en", "ua"];
  return allowedLangs.includes(lang) ? lang : "en";
};

export const sendMail = async (data) => {
  const today = new Date();
  const prefix =
    ("0" + today.getDate()).slice(-2) +
    ("0" + (today.getMonth() + 1)).slice(-2);
  const orderNr = prefix + "-" + Math.random().toString(16).slice(7);

  const { name, email, phone, address, message, order, consent } = data;
  const htmlToCustomer = await render(
    <EmailToCustomerTemplate name={name} order={order} orderNr={orderNr} />,
    { pretty: true }
  );

  const htmlToAdmin = await render(
    <EmailToAdminTemplate
      name={name}
      order={order}
      phone={phone}
      email={email}
      address={address}
      message={message}
      orderNr={orderNr}
      consent={consent}
    />,
    { pretty: true }
  );

  try {
    const result = await Promise.all([
      sendEmailToCustomer(name, email, htmlToCustomer),
      sendEmailToAdmin(name, orderNr, htmlToAdmin),
    ]);
    toast.success("Your order has been sent successfully!");
    return result;
  } catch (error) {
    toast.error("Error sending email: " + error.status);
    console.log("Error sending email: ", error);

    return error;
  }
};

async function sendEmailToCustomer(name, email, htmlContent) {
  const result = await emailjs.send(
    MAILJS_SERVICE_ID,
    MAILJS_CUSTOMER_TEMPLATE_ID,
    {
      name,
      email,
      htmlContent,
    },
    { publicKey: MAILJS_USER_ID }
  );
  return result;
}

async function sendEmailToAdmin(name, orderNr, htmlContent) {
  const result = await emailjs.send(
    MAILJS_SERVICE_ID,
    MAILJS_ADMIN_TEMPLATE_ID,
    {
      name,
      orderNr,
      htmlContent,
    },
    { publicKey: MAILJS_USER_ID }
  );
  return result;
}
