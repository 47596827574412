import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Menu, MenuItem } from "@mui/material";
import { switchLang } from "../redux/user/slice";
import { getLang } from "../redux/selectors";

export const MenuLang = ({ anchorEl, open, handleClose }) => {
  const dispatch = useDispatch();
  const lang = useSelector(getLang);

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <MenuItem
        onClick={() => {
          dispatch(switchLang("en"));
          handleClose();
        }}
      >
        <span
          className={`inline-block ${
            lang === "en" ? "bg-mainRed" : "bg-transparent"
          } rounded-full h-2 w-2 -ml-2 mr-1`}
        ></span>
        EN <Icon icon={"twemoji:flag-canada"} className="ml-1 text-lg" />
      </MenuItem>
      <MenuItem
        onClick={() => {
          dispatch(switchLang("ua"));
          handleClose();
        }}
        position=""
      >
        <span
          className={`inline-block ${
            lang === "ua" ? "bg-mainRed" : "bg-transparent"
          } rounded-full h-2 w-2 -ml-2 mr-1`}
        ></span>
        UA
        <Icon icon={"twemoji:flag-ukraine"} className="ml-1 text-lg" />
      </MenuItem>
    </Menu>
  );
};
