import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import env from "react-dotenv";
import DOMPurify from "dompurify";
import ReCAPTCHA from "react-google-recaptcha";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { sendMail } from "../data/helpers";
import { getCart, getContacts } from "../redux/selectors";
import { clearCart } from "../redux/cart/slice";
import { setContacts } from "../redux/user/slice";

const MailForm = ({ handleClose }) => {
  const [sitekey, setSitekey] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setSitekey(env.G_RECAPTCHA_SECRET);
  }, []);

  const order = useSelector(getCart);
  const contacts = useSelector(getContacts);
  const { name, email, phone, address } = contacts;
  const defaultFormData = {
    name: name || "",
    email: email || "",
    phone: phone || "",
    address: address || "",
    message: "",
    consent: true,
  };
  const [formData, setFormData] = useState({ ...defaultFormData });
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : DOMPurify.sanitize(value),
    });
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!captchaValue) {
      toast.info("Please complete the CAPTCHA");
      return;
    }
    setLoading(true);
    dispatch(
      setContacts({
        name: formData.name,
        email: formData.email,
        phone: formData.phone,
        address: formData.address,
      })
    );
    await sendMail({ ...formData, order });
    setLoading(false);

    e.target.reset();
    handleClose();
    setFormData({ ...defaultFormData }); // Reset form
    setCaptchaValue(null); // Reset CAPTCHA
    dispatch(clearCart());
  };

  return (
    <form onSubmit={handleSubmit} className="flex flex-col items-center w-full">
      <div className="relative w-full">
        <input
          type="text"
          name="name"
          placeholder="Full Name"
          value={formData.name}
          onChange={handleChange}
          className="input mb-3 md:mb-4 p-2 border bg-transparent rounded-md w-full required"
          required
          maxLength="50"
          pattern="[A-Za-z\s]+"
          title="Name should only contain letters and spaces."
        />
        {formData.name === "" && (
          <span className="required absolute top-3 right-2 text-mainRed text-2xl">
            *
          </span>
        )}
      </div>
      <div className="relative w-full">
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleChange}
          className="input mb-3 md:mb-4 p-2 border rounded-md w-full"
          required
          maxLength="50"
          pattern="^\S+@\S+\.\S+$"
          title="Please enter a valid email address."
        />
        {formData.email === "" && (
          <span className="required absolute top-3 right-2 text-mainRed text-2xl">
            *
          </span>
        )}
      </div>
      <div className="relative w-full">
        <input
          type="tel"
          name="phone"
          placeholder="Phone Number"
          value={formData.phone}
          onChange={handleChange}
          className="input mb-3 md:mb-4 p-2 border rounded-md w-full"
          required
          maxLength="15"
          pattern="^\d{10,15}$"
          title="Phone number should be 10-15 digits long."
        />
        {formData.phone === "" && (
          <span className="required absolute top-3 right-2 text-mainRed text-2xl">
            *
          </span>
        )}
      </div>
      <div className="relative w-full">
        <input
          type="text"
          name="address"
          placeholder="Address"
          value={formData.address}
          onChange={handleChange}
          className="input mb-3 md:mb-4 p-2 border rounded-md w-full"
          required
          maxLength="100"
        />
        {formData.address === "" && (
          <span className="required absolute top-3 right-2 text-mainRed text-2xl">
            *
          </span>
        )}
      </div>
      <textarea
        name="message"
        placeholder="Your Message"
        value={formData.message}
        onChange={handleChange}
        className="mb-3 md:mb-4 p-2 border rounded-md w-full"
        maxLength="200"
        rows={2}
      />
      <div className="flex items-center mb-3 md:mb-4">
        <input
          type="checkbox"
          name="consent"
          checked={formData.consent}
          onChange={handleChange}
          className="mr-2 w-4 h-4"
        />
        <label htmlFor="consent" className="text-xs text-gray-500">
          I agree to receive email updates and notifications
        </label>
      </div>
      {sitekey && (
        <ReCAPTCHA
          sitekey={sitekey}
          onChange={handleCaptchaChange}
          className="mb-3 md:mb-4 scale-90 md:scale-100"
        />
      )}
      <div className="relative w-full flex justify-center">
        <button
          type="submit"
          disabled={loading}
          className={`btn  border border-mainBlack text-mainBlack w-1/2 ${
            loading
              ? "cursor-not-allowed bg-bluredBlack hover:text-mainBlack hover:border-mainBlack "
              : "hover:text-mainRed hover:border-mainRed bg-mainGreen"
          }`}
        >
          Submit
        </button>
        {loading && (
          <CircularProgress
            size={54}
            sx={{
              color: "#c41d1844",
              position: "absolute",
              top: -11,
              left: "calc(50% - 27px)",
              zIndex: 1,
            }}
          />
        )}
      </div>
    </form>
  );
};

export default MailForm;
