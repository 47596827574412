import { useState } from "react";
import { CheckoutDialog } from "./CartDialogs";

export const CartSummary = ({ subtotal, handleClose }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseCheckout = () => {
    setOpen(false);
  };

  return (
    <div className="w-full my-2 pt-2 border-t-2 border-gray-300">
      <div className="w-full">
        <div className="w-full flex justify-between font-bold md:text-xl">
          <p>Subtotal:</p>
          <p>${subtotal}</p>
        </div>
        <p className="text-gray-500">Shipping will be calculated at checkout</p>
      </div>
      <div className="mt-2 w-full flex gap-4">
        <button
          onClick={handleClose}
          className="btn w-1/2 text-gray-400 border-gray-400  hover:border-mainBlack hover:text-mainBlack"
        >
          Back To Shop
        </button>
        <button
          onClick={() => {
            handleClose();
            handleClickOpen();
          }}
          className="btn w-1/2 border-mainBlack hover:border-mainRed hover:text-mainRed bg-mainGreen"
        >
          Checkout
        </button>
      </div>
      <CheckoutDialog open={open} handleCloseCheckout={handleCloseCheckout} />
    </div>
  );
};
