import { forwardRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { Icon } from "@iconify/react/dist/iconify.js";
import { getCart, getLang } from "../redux/selectors";
import { CartList } from "./CartList";
import { CartSummary } from "./CartSummary";
import { CartEmpty } from "./CartEmpty";
import { cutName } from "../data/helpers";
import { addToCart, updateCart } from "../redux/cart/slice";
import MailForm from "./MailForm";
import { cloudinary_base_url } from "../data/constants";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export function CartDialog({ open, handleClose }) {
  const cartList = useSelector(getCart);
  const subtotal = cartList
    .reduce((acc, curr) => acc + curr.price * curr.qty, 0)
    .toFixed(2);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      transitionDuration={350}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            margin: 0,
          },
        },
      }}
    >
      <div className="p-3 md:p-5 flex flex-col justify-start items-start">
        <div className="flex items-center justify-between w-full md:text-2xl font-semibold mb-3">
          <h3 className="text-xl md:text-3xl">Shopping cart</h3>
          <Icon
            icon="iconamoon:close"
            className="text-mainRed text-3xl cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <CartList />
        {cartList.length ? (
          <CartSummary subtotal={subtotal} handleClose={handleClose} />
        ) : (
          <CartEmpty handleClose={handleClose} />
        )}
      </div>
    </Dialog>
  );
}

export function AddDialog({ open, handleClose, product }) {
  const cloudPreset = "c_scale,w_512/q_auto/f_webp/Multicook/products/";
  const lang = useSelector(getLang);

  const [option, setOption] = useState(null);
  const [notified, setNotified] = useState(false);
  const handleChange = (e) => {
    setOption(e.target.value);
    setNotified(false);
  };

  const dispatch = useDispatch();
  const handleAdd = () => {
    if (option === null) {
      setNotified(true);
    } else {
      const { productId, productName, productImgCDN } = product;
      const { qty, weight, price } = product.variants.find(
        (el) => el.qty === option
      );

      dispatch(
        addToCart({
          productId,
          productName,
          productImgCDN,
          option: qty,
          qty: 1,
          weight,
          price: +price,
          cartId: Math.random().toString(36).slice(2),
        })
      );
      dispatch(updateCart());
      const timeOut = setTimeout(() => {
        dispatch(updateCart());
        clearTimeout(timeOut);
      }, 1000);
      handleClose();
    }
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      transitionDuration={350}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            margin: 0,
          },
        },
      }}
    >
      <div className="p-3 md:p-5 flex flex-col justify-start items-start w-[360px]">
        <div className="flex items-center justify-between w-full md:text-2xl font-semibold mb-3">
          <h3 className="text-xl md:text-3xl">Select option</h3>
          <Icon
            icon="iconamoon:close"
            className="text-mainRed text-3xl cursor-pointer"
            onClick={handleClose}
          />
        </div>
        <div className="flex flex-col items-center justify-between w-full mb-3">
          <img
            src={cloudinary_base_url + cloudPreset + product.productImgCDN}
            alt={product.productName}
            className="w-full h-[100px] object-cover rounded-md"
          />
          <h4 className="font-bold text-lg py-1">
            {cutName(product.productName[lang], "VEGA")}
          </h4>
        </div>
        <select
          onChange={handleChange}
          className="w-full font-lato p-2 mb-3 border border-mainGreen rounded-md cursor-pointer"
        >
          <option value={null}>Select weight</option>
          {product.variants.map((el, idx) => (
            <option value={el.qty} key={idx}>
              {el.qty ? el.qty + " - " : null}
              {el.weight ? el.weight + "g - " : null}${el.price}
            </option>
          ))}
        </select>
        <div className="flex items-center justify-between w-full">
          <button
            onClick={handleAdd}
            className="btn hover:text-mainGreen hover:border-mainGreen bg-mainRed text-mainWhite"
          >
            Add to cart
          </button>
          {notified && (
            <span className="text-mainRed text-sm">Please select weight</span>
          )}
        </div>
      </div>
    </Dialog>
  );
}

export const CheckoutDialog = ({ open, handleCloseCheckout }) => {
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      transitionDuration={350}
      onClose={handleCloseCheckout}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            margin: 0,
          },
        },
      }}
    >
      <div className="p-3 md:p-5 flex flex-col justify-start items-start w-[360px]">
        <div className="flex items-center justify-between w-full md:text-2xl font-semibold md:mb-3">
          <h3 className="text-xl md:text-3xl">Checkout</h3>
          <Icon
            icon="iconamoon:close"
            className="text-mainRed text-3xl cursor-pointer"
            onClick={handleCloseCheckout}
          />
        </div>
        <p className="py-4 text-sm">
          Fill out the form to complete your purchase:{" "}
        </p>
        <MailForm handleClose={handleCloseCheckout} />
      </div>
    </Dialog>
  );
};
