import { Icon } from "@iconify/react/dist/iconify.js";
import { contacts } from "../data/constants";

export const Social = ({ position }) => {
  const color = position === "header" ? "mainWhite" : "mainBlack";
  const menu = position === "menu";

  return (
    <ul
      className={`${
        menu ? "flex" : "hidden"
      } base:flex items-center justify-between gap-4 text-${color} md:text-4xl`}
    >
      {contacts.slice(2).map((el) => (
        <li key={el.id}>
          <a href={el.link} target={"_blank"} rel={"noopener noreferrer"}>
            <Icon
              icon={el.icon}
              className={`hover:text-mainGreen ${
                el.name === "e-mail"
                  ? "text-[35px] md:text-[48px]"
                  : el.name === "instagram"
                  ? "md:text-[34px]"
                  : "text-[27px] md:text-[38px]"
              }`}
            />
          </a>
        </li>
      ))}
    </ul>
  );
};
