import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contacts: { name: "", email: "", phone: "", address: "" },
  lang: "en",
  // could be added other user preferences: light/dark mode, favorites, etc.
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    switchLang(state, action) {
      state.lang = action.payload;
    },
    setContacts(state, action) {
      state.contacts = action.payload;
    },
  },
});

export const { switchLang, setContacts } = userSlice.actions;
export const userReducer = userSlice.reducer;
