import { cutName } from "../data/helpers";

export const EmailToCustomerTemplate = (props) => {
  const { name, order, orderNr } = props;

  const subtotal = order
    .reduce((acc, curr) => acc + curr.qty * curr.price, 0)
    .toFixed(2);
  const delivery = subtotal < 50 ? 4.99 : 0;
  const total = (delivery + subtotal).toFixed(2);

  return (
    <div
      style={{
        boxSizing: "border-box",
        padding: "0",
        marginTop: "0",
        marginBottom: "0",
      }}
    >
      <div>
        <div
          style={{
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            height: "80px",
          }}
        >
          <img
            src="https://res.cloudinary.com/dvloxectq/image/upload/v1740794169/Multicook/Logo_long_rbxwhd.png"
            alt="Multicook Brandon"
            height={80}
            width={"auto"}
            style={{
              height: "80px",
              width: "auto",
            }}
          />
        </div>
        <h2
          style={{
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            fontWeight: 700,
          }}
        >
          Hello {name}!
        </h2>
        <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
          <p>
            Thank you for your order! We’re thrilled to bring you delicious,
            handmade frozen treats.{" "}
          </p>
          <p>Here are your order details:</p>
        </div>
        <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
          <p style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <strong>Order Number: </strong>
            {orderNr}
          </p>
          <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <p style={{ fontWeight: 700 }}>Items Ordered: </p>
            <ul
              style={{
                paddingTop: "0.5rem",
                paddingBottom: "0.5rem",
                paddingLeft: "1.25rem",
                listStyleType: "none",
              }}
            >
              {order.map((el) => (
                <li
                  key={el.cartId}
                  style={{
                    padding: "0",
                    marginLeft: "0",
                    marginRight: "0",
                    borderLeftWidth: "4px",
                    borderColor: "#9CA3AF",
                  }}
                >
                  <span
                    style={{
                      display: "inline-block",
                      marginLeft: "0.5rem",
                      marginRight: "0.5rem",
                    }}
                  >
                    {el.qty}x
                  </span>
                  {cutName(el.productName.en, "VEGA")}
                </li>
              ))}
            </ul>
            <p style={{ fontWeight: 700 }}>
              Subtotal: <span style={{ fontWeight: 400 }}>${subtotal}</span>
            </p>
            <p style={{ fontWeight: 700 }}>
              Delivery: <span style={{ fontWeight: 400 }}>${delivery}</span>
            </p>
            <p style={{ fontWeight: 700 }}>Total: ${total}</p>
          </div>
        </div>
        <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <p style={{ fontWeight: 700 }}>What Happens Next?</p>
          <p>
            Our team is preparing your order with love and care. We’ll reach out
            shortly to confirm details and arrange delivery. Stay tuned!
          </p>
        </div>
        <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <p style={{ fontWeight: 700 }}>Stay in Touch</p>
          <p>
            📞 <strong>Phone: </strong>{" "}
            <a
              href="tel:+12047177707"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              (204) 717-7707
            </a>
          </p>
          <p>
            📧 <strong>Email: </strong>{" "}
            <a
              href="mailto:multicookbrandon@gmail.com"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              multicookbrandon@gmail.com
            </a>
          </p>
          <p>
            🌐 <strong>Website: </strong>{" "}
            <a
              href="https://www.multicookbrandon.ca/"
              style={{ textDecoration: "none", cursor: "pointer" }}
            >
              www.multicookbrandon.ca
            </a>
          </p>
        </div>
        <p style={{ fontSize: "0.75rem", lineHeight: "1rem" }}>
          ⚠️ Note: This email is sent from an unattended mailbox. Replies will
          not be read. If you need assistance, please contact us using the
          details above.
        </p>
      </div>
    </div>
  );
};

export const EmailToAdminTemplate = (props) => {
  const { name, phone, email, address, message, order, orderNr, consent } =
    props;

  const subtotal = order
    .reduce((acc, curr) => acc + curr.qty * curr.price, 0)
    .toFixed(2);
  const delivery = subtotal < 50 ? 4.99 : 0;
  const total = (delivery + subtotal).toFixed(2);

  return (
    <div
      style={{
        boxSizing: "border-box",
        padding: "0",
        marginTop: "0",
        marginBottom: "0",
      }}
    >
      <div>
        <div
          style={{
            marginTop: "1.5rem",
            marginBottom: "1.5rem",
            height: "80px",
          }}
        >
          <img
            src="https://res.cloudinary.com/dvloxectq/image/upload/v1740794169/Multicook/Logo_long_rbxwhd.png"
            alt="Multicook Brandon"
            height={80}
            width={"auto"}
            style={{
              height: "80px",
              width: "auto",
            }}
          />
        </div>
        <h2
          style={{
            marginTop: "0.5rem",
            marginBottom: "0.5rem",
            fontSize: "1.25rem",
            lineHeight: "1.75rem",
            fontWeight: 700,
          }}
        >
          New order from {name}!
        </h2>
        <div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
          <p style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <strong>Order Number: </strong>
            {orderNr}
          </p>
          <p style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <strong>Customer name: </strong>
            {name}
          </p>
          <p style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <strong>Customer phone: </strong>
            {phone}
          </p>
          <p style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <strong>Customer email: </strong>
            {email}
          </p>
          <p style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <strong>Customer address: </strong>
            {address}
          </p>
          <p style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            Customer {consent ? "" : <strong>DO NOT</strong>} agreed to receive
            email updates and notifications{" "}
          </p>
          <p style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
            <strong>Message: </strong>
            {message}
          </p>
          <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
            <p style={{ fontWeight: 700 }}>Items Ordered: </p>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead style={{ backgroundColor: "#f2f2f2" }}>
                <tr>
                  <th style={{ padding: "8px 12px", border: "1px solid #ddd" }}>
                    Name
                  </th>
                  <th style={{ padding: "8px 12px", border: "1px solid #ddd" }}>
                    Qty
                  </th>
                  <th style={{ padding: "8px 12px", border: "1px solid #ddd" }}>
                    Weight
                  </th>
                  <th style={{ padding: "8px 12px", border: "1px solid #ddd" }}>
                    Price
                  </th>
                  <th style={{ padding: "8px 12px", border: "1px solid #ddd" }}>
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {order.map((item) => (
                  <tr key={item.cartId}>
                    <td
                      style={{ padding: "8px 12px", border: "1px solid #ddd" }}
                    >
                      {cutName(item.productName.en, "VEGA")}
                    </td>
                    <td
                      style={{ padding: "8px 12px", border: "1px solid #ddd" }}
                    >
                      {item.qty}
                    </td>
                    <td
                      style={{ padding: "8px 12px", border: "1px solid #ddd" }}
                    >
                      {item.weight} g
                    </td>
                    <td
                      style={{ padding: "8px 12px", border: "1px solid #ddd" }}
                    >
                      ${item.price}
                    </td>
                    <td
                      style={{ padding: "8px 12px", border: "1px solid #ddd" }}
                    >
                      ${item.price * item.qty}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p style={{ fontWeight: 700 }}>
              Subtotal: <span style={{ fontWeight: 400 }}>${subtotal}</span>
            </p>
            <p style={{ fontWeight: 700 }}>
              Delivery: <span style={{ fontWeight: 400 }}>${delivery}</span>
            </p>
            <p style={{ fontWeight: 700 }}>Total: ${total}</p>
          </div>
        </div>
        <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <p style={{ fontWeight: 700 }}>What Happens Next?</p>
          <p>
            Prepare the order and contact the customer to confirm details and
            arrange delivery.
          </p>
        </div>
        <p style={{ fontSize: "0.75rem", lineHeight: "1rem" }}>
          ⚠️ Note: This email is sent from an unattended mailbox. Replies will
          not be read. If you need assistance, please contact us using the
          details above.
        </p>
      </div>
    </div>
  );
};
